.error {
  margin-top: 60px;
}

.img {
  width: 150px;
  height: 150px;
  margin-bottom: 15px;
}

.text {
  font-size: 20px;
}

.btnsWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 35px;
}

.btn:first-of-type {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .error {
    margin-top: 80px;
  }

  .btnsWrapper {
    flex-direction: row;
  }

  .btn:first-of-type {
    margin-bottom: 0;
    margin-right: 20px;
  }
}

@media (min-width: 1200px) {
  .img  {
    margin-bottom: 20px;
  }

  .text {
    font-size: 22px;
  }
}
  
@media (min-width: 1920px) { 
  .img { 
    width: 200px;
    height: 200px;
  }

  .text {
    font-size: 28px;
  }
}
